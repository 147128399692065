export default {
	data() {
		return {
			getPatient: {
				userType: Number,
				uuid: 'string',
				account: '',
				// 创建日期开始
				startCreateTime: null,
				// 创建日期结束
				endCreateTime: null,
				page: 1,
				size: 10
			},
			tableData: [],
			operateList: [],
			total: 0,
			//关联医生
			doctorShow: Number,
			//禁用按钮
			statusShow: Number,
		}
	},
	created() {
		this.getPatientList();
	},
	methods: {
		// 监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.getPatient.size = newSize;
			this.getPatientList()
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.getPatient.page = newPage;
			this.getPatientList()
		},
		/*获取患者列表数据*/
		getPatientList() {
			this.tableData.splice(0, this.tableData.length); //清空数组
			this.getPatient.patientUuid = this.$route.query.getDoctorManageUuid;
			this.$axios({
				method: "post",
				url: "/api/web/doctor/myDoctorPage", // 接口地址
				data: this.getPatient
			}).then(res => {
				if (res.data.code === 200) {
					this.tableData = res.data.data;
					this.totalPages = res.data.pageData.totalPages;
					this.total = res.data.pageData.totalElements;
					console.log(this.tableData)
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
	}
}
